.side-navigation-wrap {
	position: relative;
	z-index: 1;
	max-width: 240px;
	padding: side-values(120px 0 0 0);
	@include respond-to('large') {
		padding: side-values(75px 0 0 0);
	}
	@include respond-to('medium') {
		padding-top: 10px;
		max-width: 100%;
		width: 100%;
		margin-bottom: 30px;
	}
	@include respond-to('extra-small') {
		max-width: 100%;
		float: none;
	}
}

.side-nav-menu {
	max-width: 240px;
	margin: 0 auto;
	@include respond-to('medium') {
		max-width: 100%;
	}
	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		& li {
			position: relative;
			@include respond-to('medium') {
				display: inline-block;
				margin: 0 10px;
			}
			&::after {
				content: '';
				position: absolute;
				right: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(to right, rgba(254, 206, 8, 0) 0%, rgba(12, 45, 74, 0.61) 19%, rgba(12, 45, 74, 1) 41%, rgba(12, 45, 74, 1) 100%);
			}
			& a {
				display: block;
				padding: 15px 0;
				font-size: 18px;
				font-weight: 400;
				color: $text;
				transition: color 0.2s ease;
				@include respond-to('medium') {
					padding: 10px 0;
					font-size: 17px;
				}
				&:hover,
				&:focus,
				&:active {
					text-decoration: none;
				}
				&.active {
					font-weight: 900;
				}
			}
		}
	}
}