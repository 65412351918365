.all-news {
	&__item {
	}
	& .hp-news-info {
		padding: 50px;
		height: auto;
		@include respond-to('extra-small') {
			padding: 15px;
		}
		&__title {
			margin-top: 0;
			@include respond-to('small') {
				font-size: 18px;
			}
		}
		&__date {}
		&__desc {
			@include respond-to('small') {
				font-size: 17px;
			}
		}
		&__link {
			position: static;
			display: inline-block;
			margin-top: 20px;
		}
	}
}