.catalog {
	&__filter {}
	&__body {
		
	}
}

.filter-select {
	position: relative;
	width: 130px;
	height: 40px;
	// border: 1px solid #d2d2d2;
	&::after {
		position: absolute;
		#{$left}: 10px;
		top: 13px;
		content: '\f078';
		font-family: "Font Awesome 5 Pro";
		font-size: 12px;
	}
	&.sortreversorder {
		&::after {
			content: '\f077';
		}
	}
	& select {
		width: 100%;
		height: 100%;
		appearance: none;
		border: 1px solid #d2d2d2;
		padding: 0 15px;
		font-size: 16px;
		font-weight: 900;
		text-align: center;
		& option {
			font-size: 16px;
			font-weight: 400;
			color: $text;
			text-align: center;
		}
	}
}
.allc{
	margin-top: 7px;
    display: block;
    position: relative;
    width: 100%;
} 
a.allc-item {
    padding: 10px;
    border: 1px solid #0c2d4a;
    margin: 10px 2px 0 2px;
    float: right;
    position: relative;
	font-size: 15px;
	color: #ffbd59;
    font-weight: 600;
}
a.allc-item:hover{
text-decoration: none;
background: #0c2d4a;
color: #fff;
}