.wrapper-all-content {
	background-color: #fff;
	position: relative;
	width: 100%;
	padding-top: 160px;
	@include respond-to('medium') {
		padding-top: 130px;
	}
}
.section-limit.section-no-banner {
	margin-top: 180px;
}
.top-banner {
	position: relative;
	// padding: 20px 0;
	// background-image: url(../images/top-banner-bg.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 0;
	&__right {
		display: inline-block;
		padding: 20px 0;
		vertical-align: middle;
		font-size: initial;
		width: 50%;
		text-align: center;
		@include respond-to('small') {
			width: 100%;
		}
		& p {
			margin: 0;
			font-size: 30px;
			font-weight: 900;
			color: $accent;
			@include respond-to('medium') {
				font-size: 20px;
			}
			@include respond-to('small') {
				font-size: 18px;
			}
			& span {
				@include respond-to('medium') {
					font-size: 20px !important;
				}
				@include respond-to('small') {
					font-size: 17px !important;
				}
			}
		}
	}
	&__left {
		display: inline-block;
		padding: 20px 0;
		vertical-align: middle;
		font-size: initial;
		width: 50%;
		text-align: center;
		@include respond-to('small') {
			width: 100%;
		}
		& p {
			margin: 0;
			font-size: 30px;
			font-weight: 900;
			color: $text;
			@include respond-to('medium') {
				font-size: 20px;
			}
			@include respond-to('small') {
				font-size: 18px;
			}
			& span {
				@include respond-to('medium') {
					font-size: 20px !important;
				}
				@include respond-to('small') {
					font-size: 18px !important;
				}
			}
		}
	}
}

.title {
	margin: 0;
	padding: 30px 0 25px;
	border-bottom: 1px solid #d2d2d2;
	font-size: 30px;
	font-weight: 900;
	color: $title;
	@include respond-to('medium') {
		font-size: 25px;
	}
	@include respond-to('small') {
		font-size: 20px;
	}
	&_pt5 {
		padding-top: 5px;
	}
	&_mb25 {
		margin-bottom: 25px;
	}
}

.books-wr {
	padding-bottom: 45px;
	@include respond-to('medium') {
		padding-bottom: 25px;
	}
	&_grey {
		background-color: #fafafa;
	}
}

.books {
	padding-top: 35px;
	@include respond-to('medium') {
		padding-top: 25px;
	}
	&_cat {
		font-size: 0;
		text-align: center;
	}
	&__item {
		padding: 15px 5px 0;
		&_cat {
			display: inline-block;
			vertical-align: top;
			width: 16.66%;
			max-width: 225px;
			min-width: 200px;
			margin: 0 auto 30px;
			padding: 0 5px;
			font-size: initial;
			@include respond-to('larger') {
				// width: 20%;
			}
		}
	}
}

.hp-slider {
	& .books__item {
		display: none;
		&:first-child {
			display: block;
		}
		&.slick-slide {
			display: block;
		}
	}
	& .slick-arrow {
		width: 19px;
		height: 41px;
	}
	& .slick-prev {
		#{$right}: -25px;
		#{$left}: auto;
		@include respond-to('1550') {
			#{$right}: -10px;
		}
		&::before {
			content: if-rtl(url('../images/arr-r.png'), url('../images/arr-l.png'));
		}
	}
	& .slick-next {
		#{$left}: -25px;
		#{$right}: auto;
		@include respond-to('1550') {
			#{$left}: -10px;
		}
		&::before {
			content: if-rtl(url('../images/arr-l.png'), url('../images/arr-r.png'));
		}
	}
}

.hp-boxes {
	padding: 50px 0;
	font-size: 0;
	@include respond-to('medium') {
		padding: 30px 0;
		text-align: center;
	}
	&__item {
		display: inline-block;
		font-size: initial;
		width: 33.33%;
		height: 520px;
		overflow: hidden;
		@include respond-to('large') {
			height: 400px;
		}
		@include respond-to('medium') {
			width: 50%;
			text-align: $right;
		}
		@include respond-to('small') {
			width: 100%;
			height: auto;
		}
		&:last-child {
			@include respond-to('medium') {
				width: 100%;
				height: 300px;
			}
			@include respond-to('small') {
				height: auto;
			}
		}
	}
}

.hp-news {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #e0e0e0;
	padding: 50px 70px 120px;
	@include respond-to('huge') {
		padding: 30px 40px 90px;
	}
	@include respond-to('large') {
		padding: 20px 40px 90px;
	}
	@include respond-to('small') {
		padding: 20px 40px 20px;
	}
	@include respond-to('extra-small') {
		padding: 20px;
	}
	& .hp-box {
		&__title {
			color: $text;
			border-bottom: 1px solid $text;
		}
	}
}

.hp-news-info {
	position: relative;
	height: 329px;
	background-color: #e0e0e0;
	font-size: initial;
	@include respond-to('huge') {
		padding: 30px 20px;
		height: 399px;
	}
	@include respond-to('large') {
		height: 319px;
		padding: 5px 0px;
	}
	@include respond-to('small') {
		height: auto;
		padding: 5px 0px 90px;
	}
	&__title {
		position: relative;
		margin-top: 50px;
		font-size: 25px;
		font-weight: 900;
		color: $text;
		line-height: 0.8;
		@include respond-to('huge') {
			margin-top: 15px;
		}
		@include respond-to('small') {}
		@include respond-to('extra-small') {}
	}
	&__date {
		color: $text;
		font-weight: 400;
		font-size: 16px;
		line-height: 1.2;
	}
	&__desc {
		margin-top: 20px;
		font-size: 18px;
		font-weight: 400;
		color: $text;
		line-height: 1.2;
		@include respond-to('small') {
			max-width: 100%;
		}
		@include respond-to('extra-small') {
			margin-top: 15px;
			font-size: 16px;
		}
	}
	&__link {
		position: absolute;
		bottom: 60px;
		#{$right}: 0px;
		@include respond-to('huge') {
			bottom: 30px;
		}
		@include respond-to('small') {
			bottom: 10px;
		}
	}
}

.hp-box {
	position: relative;
	width: 100%;
	height: 100%;
	padding: 50px 70px 120px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include respond-to('huge') {
		padding: 30px 40px 90px;
	}
	@include respond-to('1100') {
		padding: 20px 20px 90px;
	}
	&__title {
		padding-bottom: 50px;
		border-bottom: 1px solid #fff;
		font-size: 40px;
		font-weight: 400;
		line-height: 1;
		color: #fff;
		@include respond-to('large') {
			padding-bottom: 30px;
			font-size: 30px;
		}
		& span {
			color: $accent;
			font-weight: 900;
		}
	}
	&__text {
		padding-top: 45px;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		line-height: 1.3;
		@include respond-to('large') {
			padding-top: 25px;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
		}
	}
	&__btn {
		position: absolute;
		bottom: 60px;
		#{$right}: 70px;
		@include respond-to('huge') {
			bottom: 30px;
			#{$right}: 40px;
		}
		@include respond-to('large') {
			#{$right}: 40px;
		}
		@include respond-to('extra-small') {
			#{$right}: 20px;
		}
	}
}

.main-btn {
	min-width: 150px;
	height: 60px;
	padding: 17px 33px;
	background-color: $accent;
	text-align: center;
	font-size: 18px;
	font-weight: 900;
	color: #fff;
	cursor: pointer;
	@include link_no-hover(#fff);
	&:active {
		@include btn-effect-active();
	}
}