.catalog-item {
	font-size: 0;
	padding-top: 70px;
	@include respond-to('larger') {
		padding-top: 30px;
	}
	&__right {
		display: inline-block;
		width: 25%;
		font-size: initial;
		vertical-align: top;
		@include respond-to('1100') {
			width: 45%;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
	&__center {
		display: inline-block;
		width: 50%;
		font-size: initial;
		vertical-align: top;
		padding-#{$right}: 25px;
		padding-#{$left}: 70px;
		@include respond-to('large') {
			padding-#{$left}: 25px;
		}
		@include respond-to('1100') {
			width: 55%;
		}
		@include respond-to('small') {
			width: 100%;
		}
		@include respond-to('extra-small') {
			padding: 10px 0 0;
		}
	}
	&__left {
		display: inline-block;
		width: 25%;
		font-size: initial;
		vertical-align: top;
		text-align: $left;
		@include respond-to('1100') {
			width: 100%;
			text-align: center;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
	&__similiar {
		padding-top: 70px;
	}
	& .title {
		margin: 0;
		padding: 0px 0 5px;
		font-size: 30px;
		font-weight: 900;
		color: $title;
		border-bottom: 0;
		line-height: 1;
	}
	& .subtitle {
		margin: 0;
		border-bottom: 1px solid #d2d2d2;
		margin-bottom: 25px;
		padding-bottom: 20px;
		line-height: 1;
		font-size: 18px;
		font-weight: 900;
	}
	.books-wr {
		margin-top: 60px;
		& .title {
			border-bottom: 1px solid #d2d2d2;
			padding-bottom: 25px;
			@include respond-to('small') {
				margin-top: 40px;
				font-size: 25px;
			}
			@include respond-to('extra-small') {
				margin-top: 30px;
				font-size: 20px;
			}
		}
	}
}

.book-block {
	@include respond-to('small') {
		height: 100%;
		font-size: 0;
		text-align: center;
	}
	&__img {
		max-width: 299px;
		margin: 0 auto 40px auto;
		text-align: center;
		@include respond-to('small') {
			width: 60%;
			display: inline-block;
			font-size: initial;
			vertical-align: top;
		}
		@include respond-to('mobile-l') {
			width: 60%;
			margin: 0 auto 20px auto;
		}
		& img {
			 // box-shadow: 0 0 15px 0 rgba($color: #000000, $alpha: 0.4); 
			@include respond-to('mobile-l') {
				max-height: 300px;
			}
		} 
	}
	&__btns {
		@include respond-to('small') {
			width: 40%;
			display: inline-block;
			font-size: initial;
			vertical-align: top;
		}
		@include respond-to('mobile-l') {
			width: 100%;
		}
	}
	&__present {
		width: 50%;
		float: $right;
		text-align: center;
		@include respond-to('larger') {
			width: 35%;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
		}
		& span {
			display: block;
			margin-bottom: 15px;
			font-size: 20px;
			font-weight: 900;
			color: $title;
			line-height: 1;
		}
	}
	&__soc {
		padding-top: 20px;
		padding-#{$right}: 10px;
		width: 50%;
		float: $right;
		@include respond-to('larger') {
			width: 65%;
		}
		@include respond-to('small') {
			width: 100%;
			text-align: center;
			padding-#{$right}: 0px;
		}
		& span {
			display: block;
			font-size: 20px;
			font-weight: 900;
			color: $title;
			line-height: 1;
		}
	}
}

.book-info {
	display: inline-block;
	padding-top: 70px;
	max-width: 310px;
	width: 100%;
	@include respond-to('1100') {
		padding-top: 30px;
		max-width: 60%;
		min-width: 400px;
	}
	@include respond-to('extra-small') {
		min-width: 290px;
	}
	&__old-price {
		text-align: center;
		font-size: 20px;
		font-weight: 400;
		color: #000;
		text-decoration: line-through;
	}
	&__new-price {
		text-align: center;
		font-size: 25px;
		font-weight: 900;
		color: $accent;
	}
	&__sale {
		text-align: center;
		font-size: 20px;
		font-weight: 900;
		color: $accent;
		& span {
			font-weight: 400;
			color: #000;
		}
	}
	&__btn {
		border: none;
		margin: 40px 0;
		width: 100%;
		height: 60px;
		line-height: 60px;
		text-align: center;
		color: #fff;
		background-color: $accent;
		font-size: 20px;
		font-weight: 900;
		box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.3);
		&:active {
			box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
		}
		& img {
			margin-#{$left}: 15px;
		}
	}
	&__title {
		border-bottom: 1px solid #d2d2d2;
		padding-bottom: 15px;
		text-align: center;
		font-size: 20px;
		font-weight: 900;
		line-height: 1;
		color: $text;
	}
	&__author,
	&__code,
	&__year,
	&__pages,
	&__wrapper,
	&__isbn {
		text-align: $right;
		font-size: 18px;
		font-weight: 900;
		color: $text;
		& span {
			font-weight: 400;
		}
	}
	&__isbn {
		margin-bottom: 10px;
		font-size: 20px;
		// font-weight: 900;
		color: $text;
		// direction: ltr;
		text-align: $right;
		& span {
			display: inline-block;
			&:last-child {
				font-weight: 900;
			}
		}
	}
	&__author {
		margin: 10px 0;
	}
	&__code {
		margin-bottom: 10px;
	}
}

.sub{
	font-size: 26px;
	margin-top: 0;
}
.tagpr {
    position: absolute;
    right: 0; 
    background: #f90401;
    color: #fff;
    font-size: 18px; 
    padding: 4px ;
    font-weight: 600;
}
// .rib-item{
// 	left: 20px;
// }