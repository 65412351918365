.question {
	padding-bottom: 20px;
	&__item {
		border-bottom: 1px solid #e4e4e4;
	}
	&__btn {
		& a {
			position: relative;
			display: block;
			padding: side-values(20px 0 20px 45px) !important;
			@include link_no-hover($title);
			font-size: 25px;
			font-weight: 900;
			@include respond-to('1100') {
				padding: side-values(15px 0 15px 45px) !important;
			}
			@include respond-to('medium') {
				font-size: 20px;
			}
			@include respond-to('small') {
				font-size: 18px;
				font-weight: 700;
			}
			@include respond-to('extra-small') {
				padding: side-values(10px 0 10px 45px) !important;
			}
			&::after {
				content: url('../images/arr-down.png');
				position: absolute;
				#{$left}: 15px;
				top: 50%;
				transform: translateY(-50%) rotate(180deg);
			}
			&.collapsed {
				&::after {
					transform: translateY(-50%) rotate(0deg);
				}
			}
		}
	}
	&__body {
		& .content-text {
			padding-bottom: 25px;
			& * {
				font-size: 18px;
				font-weight: 400;
				color: $text;
				@include respond-to('small') {
					font-size: 16px;
				}
			}
		}
	}
}