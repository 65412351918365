.filter {
	margin-bottom: 70px;
	margin-top: 60px;
	padding: 40px 40px 50px;
	box-shadow: 0 0 25px 0 rgba($color: #000000, $alpha: 0.2);
	text-align: center;
	@include respond-to('medium') {
		margin-bottom: 40px;
		margin-top: 30px;
	}
	@include respond-to('small') {
		padding: 40px 20px 50px;
	}
	@include respond-to('extra-small') {
		padding: 20px 10px 40px;
	}
	&__field {
		position: relative;
		display: inline-block;
		padding: 10px 8px;
		height: 80px;
		max-width: 320px;
		width: 100%;
		@include respond-to('small') {
			max-width: 290px;
		}
		&_select {
			&::after {
				position: absolute;
				#{$left}: 22px;
				top: 32px;
				content: '\f078';
				font-family: "Font Awesome 5 Pro";
				font-size: 16px;
			}
		}
	}
	&__input {
		padding: 0 30px;
		width: 100%;
		height: 100%;
		background-color: #f9f9f9;
		border: none;
		font-size: 18px;
		font-weight: 700;
		color: $text;
		&::placeholder {
			font-size: 18px;
			font-weight: 700;
			color: $text;
		}
	}
	&__select {
		padding: 0 30px;
		width: 100%;
		height: 100%;
		appearance: none;
		background-color: #f9f9f9;
		border: none;
		font-size: 18px;
		font-weight: 700;
		color: $text;
	}
	&__btn {
		margin-top: 30px;
		& .main-btn {
			border: none;
			max-width: 160px;
			width: 100%;
			height: 60px;
			text-align: center;
			color: #fff;
			background-color: $accent;
			font-size: 20px;
			font-weight: 900;
			box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.3);
			&:active {
				box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
			}
		}
	}
}

.br-hide {
	@include respond-to('1100') {
		display: none;
	}
}