.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	max-height: 160px;
	// overflow: hidden;
}
.arrowm{
	color: #fff;
    opacity: 0.8;
    position: absolute;
	#{$left}: 0; 
    top: 70px;
}
.header-desktop {
	height: 100px;
	transition: height 0.2s ease;
	box-shadow: 0 0 15px 1px rgba($color: #000000, $alpha: 0.2);
	@include respond-to('medium') {
		display: none;
	}
	& .section-limit {
		position: relative;
		height: 100%;
	}
	&__top {
		height: 40px;
		background-color: $accent;
		position: relative;
		z-index: 5;
		& .section-limit {
			position: relative;
			z-index: 5; 
		}
	}
	&__bottom {
		height: 60px;
		box-shadow: 0 0 35px 0 rgba($color: #000000, $alpha: 0.5);
		background-color: #fff;
		position: relative;
		z-index: 4;
		& .section-limit {
			position: relative;
			z-index: 4;
		}
	}
	&__logo {
		pointer-events: none;
		position: absolute;
		#{$right}: if-rtl(-40px, -20px);
		top: 0;
		z-index: 1;
		width: 592px;
		height: 100px;
		background-image: if-rtl(url(../images/logo-bg.png), url(../images/logo-bg-left.png));
		background-position: top $right;
		background-size: cover;
		background-repeat: no-repeat;
		@include respond-to('1880') {
			#{$right}: if-rtl(-275px, -275px);
		}
	

		@include respond-to('1700') {
			#{$right}: if-rtl(-175px, -175px);
		}

		@include respond-to('1595') {
			#{$right}: if-rtl(-227px, -227px);
		}

		@include respond-to('1505') {
			#{$right}: if-rtl(-18%, -18%);
		}
		@include respond-to('huge') {
			#{$right}: if-rtl(-300px, -290px);
		} 
		@include respond-to('large') {
			#{$right}: if-rtl(-355px, -335px);
		}
	}
}

.logo {
	position: absolute;
	#{$right}: 15px;
	top: 15px;
	z-index: 11;
	pointer-events: all;
	transition: all 0.2s ease;
	@include respond-to('large') {
		top: 25px;
	}
	& a {
		display: block;
		transition: all 0.2s ease;
	}
	& img {
		transition: all 0.2s ease;
		width: if-rtl(100%, 86%);
		margin-top: if-rtl(0, 3px);
		@include respond-to('large') {
			max-width: 140px;
		}
	}
	&_left {
		#{$right}: auto;
		#{$left}: 8px;
		top: 7px;
		@include respond-to('1100') {
			top: 15px;
		}
		& img {
			@include respond-to('1100') {
				max-width: 70px;
			}
		}
	}
}

.header-limit {
	width: 100%;
	margin: 0 auto;
	padding: side-values(0 255px 0 110px);
	height: 100%;
	@include respond-to('large') {
		padding: side-values(0 175px 0 95px);
	}
	@include respond-to('1100') {
		padding: side-values(0 175px 0 65px);
	}
}

.header-top {
	height: 100%;
	&__delivery {
		float: $right;
		margin-top: 10px;
		font-size: 16px;
		font-weight: 900;
		color: #fff;
		@include respond-to('1750') {
			color: $text;
		}
		@include respond-to('1100') {
			font-size: 15px;
		}
		& i {
			margin-#{$left}: 5px;
			font-size: 18px;
		}
	}
	&__cart {
		float: $left;
		margin-#{$left}: 60px;
		margin-top: 4px;
		@include link_no-hover(#fff);
		font-size: 18px;
		font-weight: 400;
		@include respond-to('1100') {
			margin-#{$left}: 10px;
			font-size: 17px;
		}
		& span {
			display: inline-block;
			vertical-align: bottom;
			line-height: 1;
		}
		& img {
			display: inline-block;
			margin-#{$left}: 5px;
			vertical-align: bottom;
			line-height: 1;
		}
	}
	&__soc {
		float: $left;
		margin-top: 8px;
		line-height: 1;
		& a {
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			margin-#{$right}: 10px;
			@include link_no-hover(#fff);
			& i {
				font-size: 24px;
			}
		}
	}
	&__lang {
		float: $left;
		margin-top: 7px;
		margin-#{$right}: 30px;
		& a {
			font-size: 18px;
			font-weight: 900;
			color: #ffffff;
			@include link_no-hover(#fff);
		}
	}
}

.cart-amount {
	font-size: 25px;
	font-weight: 900;
	color: #0c2d4a;
}

.main-navi {
	// width: 100%;
	display: inline-block;
	padding: 0;
	transition: padding 0.2s ease;
	& > ul {
		display: inline-block;
		vertical-align: top;
		list-style: none;
		padding: 0;
		margin: 0;
		padding-top: 12px;
		transition: all 0.2s ease;
		& > li {
			position: relative;
			float: $right;
			padding: 0 20px;
			@include respond-to('huge') {
				padding: 0 10px;
			}
			&::after {
				content: '';
				position: absolute;
				#{$left}: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 23px;
				width: 1px;
				background-color: #d6d6d6;
			}
			&:first-child {
				padding-#{$right}: 0;
				& a {}
			}
			&:last-child {
				&::after {
					display: none;
				}
			}
			&:hover {
				& .drop-menu {
					transform: scaleY(1) translateX(-50%);
					max-height: 100vh;
					opacity: 1;
					z-index: 1;
				}
			}
			& > a {
				display: block;
				padding: 3px 0;
				font-size: 20px;
				font-weight: 600;
				color: $text;
				transition: all 0.2s ease;
				border-bottom: 2px solid transparent;
				@include respond-to('huge') {
					font-size: 19px;
				}
				@include respond-to('1100') {
					font-size: 18px;
					// padding: 6px 1px 2px;
				}
				&:hover {
					text-decoration: none;
					border-bottom: 2px solid $accent;
				}
				&:focus,
				&:active {
					text-decoration: none;
					border-bottom: 2px solid $accent;
				}
				&.active {
					border-bottom: 2px solid $accent;
				}
			}
		}
	}
}

.find-block {
	float: $left;
	// height: 100%;
	margin-top: 6px;
	&__search {}
	&__advanced {
		padding-#{$right}: 15px;
		line-height: 1;
		& a {
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			@include link_no-hover($text);
		}
		&_mobile {
			max-width: 90%;
			width: 100%;
			margin: 0 auto;
			& a {
				@include link_no-hover(#fff);
			}
		}
	}
	&_mobile {
		float: none;
		& .search {
			max-width: 90%;
			width: 100%;
			margin: 0 auto;
			& input {
				color: #fff;
				&::placeholder {
					color: #fff;
				}
			}
			& i {
				font-size: 20px;
				color: #fff;
			}
		}
	}
}

.search {
	position: relative;
	width: 240px;
	@include respond-to('1100') {
		width: 180px;
	}
	& input {
		width: 100%;
		border: 1px solid #d6d6d6;
		padding: side-values(3px 3px 3px 40px);
		font-size: 16px;
		font-weight: 900;
		// text-indent: 5px;
		background-color: transparent;
		color: $text;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			padding: side-values(3px 3px 3px 40px);
			font-size: 14px;
		}
		&::placeholder {
			font-size: 16px;
			font-weight: 900;
			color: $text;
			@include respond-to('1100') {
				font-size: 14px;
			}
		}
	}
	& button {
		position: absolute;
		top: 3px;
		#{$left}: 10px;
		border: none;
		background: none;
		transition: all 0.2s ease;
		@include respond-to('1100') {
			// top: 15px;
		}
		& i {
			color: $text;
			font-size: 20px;
		}
	}
}

.header-mobile {
	display: none;
	height: 70px;
	background-color: #fff;
	box-shadow: 0 0 15px 1px rgba($color: #000000, $alpha: 0.2);
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-#{$left}: 15px;
		margin-top: 5px;
		float: $left;
		@include respond-to('small') {
			// margin-top: 10px;
		}
		@include respond-to('extra-small') {
			margin-top: 15px;
			margin-#{$left}: 10px;
		}
		& img {
			max-width: 280px;
			@include respond-to('small') {
				max-width: 220px;
			}
			@include respond-to('extra-small') {
				max-width: 120px;
			}
		}
	}
	&__tel {
		display: inline-block;
		vertical-align: top;
		margin-top: 19px;
		@include respond-to('mobile-l') {
			margin-top: 25px;
		}
		i {
			color: $text;
			font-size: 32px;
			@include respond-to('mobile-l') {
				font-size: 22px;
			}
		}
	}
	&__soc {
		display: inline-block;
		vertical-align: top;
		margin-top: 19px;
		@include respond-to('mobile-l') {
			margin-top: 25px;
		}
		& a {
			margin: 0 5px;
			& i {
				font-size: 32px;
				color: $text;
				@include respond-to('mobile-l') {
					font-size: 22px;
				}
			}
		}
	}
}

.logo-mob1 {
	& img {
		max-width: 140px;
		@include respond-to('extra-small') {
			max-width: 110px;
		}
	}
}
.logo-mob2 {
	@include respond-to('mobile-m') {
		display: none;
	}
	& img {
		max-width: 60px;
		@include respond-to('extra-small') {
			max-width: 45px;
		}
	}
}

.mobile-menu-btn {
	margin-top: 20px;
}

.top-nav-wr {
	background-color: $text;
	@include respond-to('small') {
		overflow-x: auto;
	}
	& .section-limit {}
}

.top-nav {
	height: 60px;
	width: 100%;
	display: flex;
	margin: 0;
	padding: 0;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: stretch;
	@include respond-to('small') {
		min-width: 750px;
	}
	&__item {
		position: relative;
		flex: 1 1 auto;
		align-self: center;
		list-style: none;
		color: #fff;
		text-align: center;
		@include respond-to('small') {
			flex: 1 1 60px;
		}
		&:hover {
			& > a {
				// font-weight: 900;
				@include link_no-hover($title);
			}
			& .drop-menu {
				transform: scaleY(1) translateX(-50%);
				max-height: 100vh;
				opacity: 1;
			}
			&::after,
			&::before {
				background-color: $title;
			}
			&::after {
				z-index: 3;
			}
		}
		&::after {
			z-index: 1;
			content: '';
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			height: 40px;
			width: 1px;
			background-color: #2e618d;
		}
		&::before {
			z-index: 2;
			content: '';
			position: absolute;
			right: -1px;
			top: 50%;
			transform: translateY(-50%);
			height: 40px;
			width: 1px;
			background-color: #2e618d;
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
		&:first-child {
			&::before {
				display: none;
			}
		}
		& a {
			display: inline-block;
			padding: 10px 0;
			@include link_no-hover(#fff);
			font-size: 20px;
			font-weight: 600;
			line-height: 1;
			@include respond-to('large') {
				font-size: 18px;
				padding: 10px 10px;
			}
			@include respond-to('medium') {
				font-size: 18px;
			}
		}
		// &.active {
		// 	color: $title;
		// 	font-weight: 900;
		// }
	}
}

.drop-menu {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: scaleY(0) translateX(-50%);
	max-height: 0;
	min-width: 280px;
	opacity: 0;
	margin: 0 auto;
	transform-origin: top;
	transition: all 0.3s ease;
	background-color: rgba($color: $title, $alpha: 1);
	& ul {
		position: relative;
		width: 100%;
		height: 100%;
		padding: 10px 35px;
		list-style: none;
		margin: 0;
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: -2px;
			left: 50%;
			transform: rotate(45deg) translateX(-50%);
			width: 15px;
			height: 15px;
			background-color: $title;
			pointer-events: none;
		}
	}
	& li {
		width: 100%;
		display: block;
		&:last-child {
			& a {
				border-bottom: 1px solid transparent;
			}
		}
		& a {
			display: block;
			border-bottom: 1px solid #fff;
			padding: 14px 0px;
			color: $text;
			text-align: center;
			font-size: 20px;
			font-weight: 600;
			line-height: 0.9;
			transition: background 0.3s ease, border 0.3s ease;
			@include respond-to('large') {
				// font-size: 20px;
				padding: 10px 0px;
			}
			&:hover {
				text-decoration: none;
				font-weight: 600;
				color: $accent !important;
			}
			&:focus,
			&:active {
				text-decoration: none;
			}
			// &.active {
			// 	color: #fff;
			// }
		}
	}
}