footer {
	position: relative;
	width: 100%;
	padding: 70px 0 10px;
	z-index: 1;
	box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
	background-color: #fff;
	@include respond-to('large') {
		padding: 20px 0 10px;
	}
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	&__right {
		width: 35%;
		float: $right;
		@include respond-to('1100') {}
		@include respond-to('medium') {
			float: none;
			width: 100%;
			max-width: 250px;
			margin: 0 auto 15px;
			text-align: center;
			margin-bottom: 0;
		}
	}
	&__left {
		width: 65%;
		float: $left;
		padding-top: 40px;
		@include respond-to('1100') {}
		@include respond-to('medium') {
			float: none;
			width: 100%;
			padding-top: 20px;
		}
	}
}

.link-col {
	float: $right;
	width: 33%;
	@include respond-to('extra-small') {
		width: 50%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid $accent;
	margin-top: 60px;
	margin-bottom: 20px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	// text-align: center;
	@include respond-to('medium') {
		text-align: center;
	}
	& img {
		// max-width: 216px;
		@include respond-to('medium') {
			// max-width: 150px;
		}
	}
}

.ftr-contact {
	padding-top: 40px;
	padding-#{$left}: 15px;
	@include respond-to('1100') {
		max-width: 250px;
		margin: 10px auto 10px;
		padding-top: 15px;
		padding-#{$left}: 0px;
	}
	@include respond-to('medium') {
		padding-top: 0;
		text-align: $right;
	}
	&__item {
		display: block;
		margin-bottom: 10px;
		color: #000;
		@include link_no-hover(#000);
		@include respond-to('medium') {
			margin-bottom: 5px;
		}
		& img {
			display: inline-block;
			vertical-align: middle;
			margin-#{$left}: -4px;
			color: $accent;
			font-size: 22px;
		}
		& span { 
			// display: inline-block;
			// vertical-align: middle;
			// padding-#{$right}: 15px;
			// max-width: 300px;
			// width: 85%;
			// font-size: 18px;
			// font-weight: 400;
			// line-height: 1;
		}
	}
	&__text {
		color: $accent;
		font-size: 18px;
		font-weight: 900;
		padding-#{$right}: 30px;
	}
}
span.spanf {
	padding-#{$right}: 5px;
}
span.spanf-last { 
	padding-#{$right}: 1px;
}
.spanfooter{ 
	padding-#{$right}: 26px; 
}
.site-footer__credit-holder {
	border-top: 1px solid #dedede;
	margin-top: 20px;
	padding: 0px 0 20px;
	@include respond-to('medium') {
		padding: 0px 0 10px;
	}
}

.ftr-soc {
	@include respond-to('medium') {
		text-align: $right;
	}
	&__item {
		display: inline-block;
		margin-#{$left}: 5px;
		font-size: 22px;
		@include link_no-hover($accent);
	}
}