.wrap-content {
	padding: 0px 0 25px;
	@include respond-to('large') {
		padding: 0px 0 15px;
	}
	&_shadow {
		min-height: 70vh;
		height: 100%;
		width: 100%;
		padding-#{$right}: 30px;
		@include respond-to('1100') {
			padding-#{$right}: 0;
		}
		@include respond-to('medium') {
			min-height: auto;
			box-shadow: none;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.content-str {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		#{$right}: 16%;
		height: 100%;
		width: 50px;
		background-color: transparent;
		box-shadow: if-rtl(-31px 0 25px 0 rgba(0, 0, 0, 0.05), 31px 0 25px 0 rgba(0, 0, 0, 0.05));
		@include respond-to('large') {
			#{$right}: 17%;
		}
		@include respond-to('1100') {
			#{$right}: 20%;
		}
		@include respond-to('medium') {
			display: none;
		}
	}
	&__right {
		position: relative;
		width: 21%;
		float: $right;
		padding-#{$left}: 48px;
		@include respond-to('larger') {
			padding-#{$left}: 30px;
		}
		@include respond-to('large') {
			width: 23%;
		}
		@include respond-to('1100') {
			width: 26%;
			padding-#{$left}: 15px;
		}
		@include respond-to('medium') {
			// display: none;
			float: none;
			width: 100%;
			padding: 0;
		}
	}
	&__left {
		float: $left;
		width: 79%;
		height: 100%;
		padding-bottom: 50px;
		@include respond-to('huge') {
			// width: 75%;
		}
		@include respond-to('large') {
			width: 77%;
		}
		@include respond-to('1100') {
			padding-#{$right}: 15px;
			width: 74%;
		}
		@include respond-to('medium') {
			float: none;
			width: 100%;
			padding-#{$right}: 0;
			padding-bottom: 10px;
		}
	}
}

.content-text {
	h2,
	h3 {
		font-size: 25px;
		font-weight: 700;
		line-height: 1.2;
		color: $text;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 22px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		margin-bottom: 15px;
		font-size: 18px;
		font-weight: 400;
		color: $text;
		line-height: 1.3 !important;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	ul {
		list-style: none;
		font-size: 18px;
		font-weight: 400;
		color: $text;
		line-height: 1.4;
		padding: 0;
		& li {
			position: relative;
			padding-#{$right}: 20px;
			&::after {
				content: '';
				position: absolute;
				right: 0;
				top: 8px;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background-color: $accent;
			}
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}