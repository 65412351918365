.cart-page {
	position: relative;
	padding-top: 50px;
	@include respond-to('small') {
		padding-top: 30px;
	}
	.loader-bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 100;
		&.hidden {
			display: none;
		}
		.loader {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto auto;
			width: 40px;
			height: 40px;
		}
	}
}
.cart-lower-btns {
	margin: 40px 0px;
	text-align: $left;
	@include respond-to('small') {
		margin: 25px 0px;
	}
	& button {
		padding: 0;
		color: #fff;
		box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.2);
		&:active {
			box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
		}
	}
	& a {
		@include link_no-hover($text);
		box-shadow: 0 0 10px 0 rgba($color: #000000, $alpha: 0.2);
		&:active {
			box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.2);
		}
	}
}

.cart-items-table {
	margin: 0px 0px;
	box-shadow: 0 0 25px 1px rgba($color: #000000, $alpha: 0.2);
	& tr {
		// vertical-align: middle !important;
	}
	& td {
		position: relative;
		vertical-align: middle !important;
		padding: 0px 2px !important;
		border: none !important;
		text-align: center;
		@include respond-to('medium') {
			padding: 2px 2px !important;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 10px;
			height: 60px;
			width: 1px;
			background-color: #d6d6d6;
			@include respond-to('medium') {
				display: none;
			}
		}
		&:first-child {
			padding: side-values(0 2px 0 2px) !important;
			@include respond-to('medium') {
				padding: 2px 2px !important;
			}
		}
		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}

.inner-td {
	&_2 {
		text-align: $right;
		padding: side-values(0 20px 0 2px) !important;
		@include respond-to('small') {
			max-width: 400px;
			padding: side-values(0 5px 0 2px) !important;
		}
	}
	&_4 {
		min-width: 120px;
		@include respond-to('extra-small') {
			min-width: 75px;
		}
	}
}

.cart-cell-white {
	background-color: #fff;
}

.cart-image img {
	max-width: 75px;
}

.cart-image-hide {
	@include respond-to('medium') {
		display: none !important;
	}
}

.remove-cart-item {
	cursor: pointer;
	width: 25px;
	height: 32px;
	margin: 0 auto;
	// display: block;
	@include respond-to('medium') {
		height: 27px;
	}
	svg {
		width: 25px;
		@include respond-to('medium') {
			width: 22px;
		}
	}
	&:hover {
		svg {
			fill: red;
		}
	}
}
.remove-cart-item-input {
	visibility: hidden;
	position: absolute;
}

.cart-panel {
	width: 100%;
	height: 47px;
	background-color: #0c2d4a;
	color: #fff;
	font-size: 24px;
	padding-top: 5px;
	padding-right: 10px;
	padding-left: 10px;
	border: 1px solid #ddd;
	margin: 2px 0px;
}

.cart-section {}

.cart-svg-icon {
	padding-top: 10px;
}

.cart-td {
	vertical-align: middle !important;
}

.nmargin {
	margin: 0;
}

.cart-fgroup {
	label {
		width: 100px;
	}
	select {
		min-width: 200px;
	}
	input {
		min-width: 200px;
	}
	.text {
		width: 200px;
		display: inline-block;
	}
}

.cart-refreash {
	margin-right: 10px;
	cursor: pointer;
	display: inline-block;
	color: green;
	i {
		margin-bottom: -3px;
		display: block;
		float: right;
	}
}

.prod-price-buy {
	display: inline-block;
	vertical-align: top;
	max-width: 220px;
	width: 100%;
	height: 60px;
	border: none;
	color: #fff;
	font-weight: 900;
}

.return-btn.main-btn {
	display: inline-block;
	vertical-align: top;
	margin-#{$left}: 25px;
	@include link_no-hover(#fff);
	background-color: $text;
	@include respond-to('extra-small') {
		margin-#{$left}: 0;
		margin-bottom: 10px;
		max-width: 220px;
		width: 100%;
	}
}

.table {
	color: $text;
}

.comment-area {
	color: #000;
}

.page-header {
	position: relative;
	z-index: 100;
	margin-bottom: 20px;
}

.modal-cart-btn {
	display: inline-block;
	vertical-align: top;
	max-width: 190px;
	width: 100%;
	height: 40px;
	border: none;
	text-align: center;
	font-weight: 700;
	margin: 3px 0;
	&_2 {
		background-color: $accent;
		padding-top: 8px;
		@include link_no-hover(#000);
	}
}

.cart-modal {
	& .modal-dialog {
		box-shadow: 0 0 20px 5px rgba($color: $accent, $alpha: 0.1);
	}
	& .modal-content {
		background-color: #fff;
		border-radius: 0;
	}
	& .modal-age {
		position: static;
		transform: none;
		max-width: 100%;
		// width: 100%;
		height: unset;
	}
	& .modal-header {
		border-bottom: 1px solid $accent;
	}
	& .modal-footer {
		border-top: none;
	}
	& .modal-title {
		color: $text;
		font-weight: 700;
		line-height: 1;
	}
	& .close {
		margin: 0;
		color: $text;
		opacity: 0.7;
		&:hover {
			opacity: 1;
		}
	}
}

.prod-title {
	font-size: 20px;
	font-weight: 900;
	color: $text;
	line-height: 1;
	@include respond-to('medium') {
		font-size: 18px;
	}
	@include respond-to('small') {
		text-align: center;
		line-height: 0.9;
		font-size: 16px;
	}
	@include respond-to('extra-small') {
		font-size: 14px;
		font-weight: 700;
	}
}

.prod-author {
	display: block;
	font-size: 14px;
	font-weight: 400;
	color: $text;
	line-height: 1;
}

.prod-sale {
	font-size: 16px;
	font-weight: 400;
	line-height: 1;
	& span {
		color: $accent;
		font-weight: 900;
	}
}

.prod-price {
	font-size: 20px;
	font-weight: 900;
	color: $accent;
	line-height: 1;
	@include respond-to('small') {
		font-size: 16px;
		font-weight: 700;
	}
}

.total-price {
	margin-top: 45px;
	float: $left;
	font-size: 30px;
	font-weight: 400;
	color: $text;
	@include respond-to('small') {
		margin-top: 25px;
		font-size: 25px;

	}
	& span {
		font-size: 40px;
		font-weight: 900;
		@include respond-to('small') {
			font-size: 30px;
		}
	}
}

.cart-units {
	display: inline-block;
	vertical-align: middle;
	height: 33px;
	margin: 0 auto;
	@include respond-to('extra-small') {
		height: 20px;

	}
	.cart--units-button {
		border: 0;
		font-size: 12px;
		float: $right;
		width: 33px;
		height: 100%;
		background-color: $text;
		color: #fff;
		// box-shadow: 0 0 7px 2px rgba($color: #000000, $alpha: 0.5);
		@include respond-to('extra-small') {
			width: 20px;
		}
		&:disabled {
			cursor: not-allowed;
		}
	}
	.cart-units--add {}
	.cart-units--remove {}
	.cart-units--input {
		border: 0;
		float: $right;
		width: 50px;
		height: 100%;
		text-align: center;
		background-color: #f3f3f3;
		direction: ltr;
		color: $text;
		font-size: 18px;
		font-weight: 900;
		@include respond-to('extra-small') {
			width: 30px;
			font-size: 16px;
			font-weight: 700;
			padding: 1px;
		}
	}
}