.book-item {
	padding-bottom: 5px;
	text-align: center;
	&__img-wr {
		position: relative;
		width: 190px;
		margin: 0 auto;
		&:hover {
			.ribbon-box {
				opacity: 0;
			}
		}
	}
	&__img {
		position: relative;
		overflow: hidden;
		height: 280px;
		width: 190px;
		margin-bottom: 18px;
		margin-left: auto;
		margin-right: auto;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain; 
		box-shadow: 0 0 12px 1px rgba($color: #000000, $alpha: 0.1);
		&:hover { 
			// z-index: 1;
			& .book-item__info {
				// z-index: 2;
				bottom: 0;
			}
		} 
	}
	&__title {
		// height: 25px;
		height: 48px;
		overflow: hidden;
		font-size: 20px;
		font-weight: 900;
		color: $text;
		line-height: 1.2;
	}
	&__author {
		font-size: 16px;
		font-weight: 600;
		color: $text; 
		line-height: 1;
		height: 30px;
		overflow: hidden;
	} 
	&__subtitle {
		height: 35px;
		overflow: hidden;
		font-size: 15px;
		font-weight: 600;
		color: $text; 
		line-height: 1.2;
		overflow: hidden;
	}
	&__prices {
		margin-top: 18px;
		border-top: 1px solid #d2d2d2;
		padding: 18px 0;
	}
	&__btns {}
	&__info {
		position: absolute;
		bottom: -100%;
		width: 100%;
		height: 100%;
		z-index: 99;
		transition: all 0.3s ease-in-out;
	}
}

.book-item-price {
	text-align: center;
	&__old {
		display: inline-block;
		font-size: 16px;
		font-weight: 400;
		color: #000;
		text-decoration: line-through;
	}
	&__new {
		display: inline-block;
		font-size: 20px;
		font-weight: 900;
		color: $accent;
	}
}

.book-item-btn {
	font-size: 0;
	text-align: center;
	&__search {
		display: inline-block;
		vertical-align: middle;
		padding: 0 15px;
		border-#{$left}: 1px solid #000;
		font-size: 20px;
		@include link_no-hover($text);
	}
	&__form {
		display: inline-block;
		vertical-align: middle;
		padding: 0 15px;
		font-size: 20px;
		@include link_no-hover($accent);
	}
	&__cart {
		background-color: transparent;
		border: none;
		padding: 0;
	}
}

.book-item-info {
	height: 100%;
	background-color: rgba($color: $text, $alpha: 0.85);
	padding: 15px 15px;
	color: #fff;
	text-align: center;
	line-height: 1;
	&__title {
		margin-bottom: 4px;
		font-size: 20px;
		font-weight: 900;
	}
	&__desc {
		font-size: 14px;
		font-weight: 700;
	}
	&__author {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 400;
	}
	&__desc2 {
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 400;
	}
	&__link {
		display: inline-block;
		margin-bottom: 10px;
		font-size: 14px;
		font-weight: 700;
		@include link_no-hover(#fff);
	}
	&__price {}
	&__price-old {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 400;
		text-decoration: line-through;
	}
	&__price-new {
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		font-weight: 700;
	}
	&__btn {
		display: inline-block;
		width: 95px;
		height: 30px;
		margin: 15px auto 0;
		padding: 6px;
		@include link_no-hover(#fff);
		font-size: 14px;
		font-weight: 700;
		background: #fff;
	}
}

.ribbon-box {
	pointer-events: none;
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 94px;
	height: 94px;
	opacity: 1;
	transition: opacity 0.4s ease;
	& > span {
		font-size: 15px;
		font-weight: 700;
		color: #fff;
		text-align: center;
		height: 25px;
		transform: rotate(-45deg);
		width: 115px;
		display: block;
		background: $accent;
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 20px;
		left: -25px;
		padding: 1px 15px;
		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid #5a5a5a;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #5a5a5a;
		}
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-right: 3px solid #5a5a5a;
			border-bottom: 3px solid transparent;
			border-top: 3px solid #5a5a5a;
		}
	}
	&_big {
		width: 147px;
		height: 147px;
		& > span {
			width: 180px;
			background: #0a1322;
			top: 43px;
			left: -34px;
		}
	}
}
